import React from "react"
// import { Link } from "gatsby"
// import Img from "gatsby-image"
import { GatsbyImage } from "gatsby-plugin-image"

import { graphql } from "gatsby"
import useHover from "react-use-hover"
import Link from "gatsby-link"

import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"

import Layout from "../components/layout"
import SEO from "../components/seo"
import CTA from "../components/cta"
import TopButton from "../components/top-button"

import "./esp.scss"

const ListItem = (el) => {
  const [isHovering, hoverProps] = useHover({
    mouseEnterDelayMS: 0,
    mouseLeaveDelayMS: 0,
  })
  const background = isHovering
    ? `radial-gradient(137.50px at 50% 50%, #FFFFFF 0%, ${el.color} 100%)`
    : "#fff"

  return (
    <a
      href={`#${el.id}`}
      {...hoverProps}
      className="esp-list__item"
      key={el.title}
    >
      <GatsbyImage
        className="esp-list__item__image"
        image={el.imageSmall.childImageSharp.gatsbyImageData}
        imgStyle={{ background }}
        alt={el.title}
        loading="eager"
        critical
        fadeIn
      />
      <div
        className="text"
        style={{
          background: isHovering ? el.color : undefined,
          color: isHovering ? "#fff" : "#000",
        }}
      >
        {el.title}
      </div>
    </a>
  )
}

const ESP = ({ data }) => {
  const profilesGQL = data && data.allProfilesJson && data.allProfilesJson.edges
  return (
    <Layout>
      <SEO title="Employee SHAPE Profile" />
      {/* <TopButton topPosition={650} showPosition={800} /> */}
      <div className="heroEsp">
        <h1>Employee SHAPE Profile</h1>
        <Container>
          <Row>
            <Col xs={12} sm={4}>
              <h3>Know yourself</h3>
              <p>
                Which of the 16 profiles represents your identity? Reveal your
                superpowers. Find out how you can become a star performer with
                ESP.
              </p>
            </Col>
            <Col xs={12} sm={4}>
              <h3>Understand your peers</h3>
              <p>
                What drives those around you? What are their struggles? Build
                meaningful relationships. Empower and succeed.
              </p>
            </Col>
            <Col xs={12} sm={4}>
              <h3>Take control</h3>
              <p>
                Change what’s holding you back. Take charge and drive action.
                Reshape. Ready, set, go!
              </p>
            </Col>
          </Row>
        </Container>
        <Link to="/contact">What's your ESP?</Link>
      </div>
      <div className="container-fluid" id="esp-list">
        <h2 className="esp-list-heading">Which One Are You?</h2>
        <div className="esp-list">
          {profilesGQL.map(({ node }) => ListItem(node))}
        </div>
      </div>
      <div>
        {profilesGQL.map(({ node }) => (
          <div
            className="profile-item"
            style={{ color: node.color }}
            key={node.title}
            id={node.id}
          >
            <div className="container-fluid">
              <div className="flex-wrapper">
                <div className="imageEsp">
                  {/* <Img
                    fluid={node.image.childImageSharp.fluid}
                    alt={node.title}
                  /> */}
                  <GatsbyImage
                    image={node.image.childImageSharp.gatsbyImageData}
                    alt={node.title}
                    fadeIn
                  />
                </div>
                <div className="body">
                  <h4>{node.title}</h4>
                  <p>
                    <b>“{node.subtitle}”</b>
                  </p>
                  <p className="noMargin">{node.text}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Layout>
  )
}

export default ESP

export const query = graphql`
  query EspProfilesQuery {
    allProfilesJson {
      edges {
        node {
          title
          subtitle
          text
          id
          color
          image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          imageSmall {
            childImageSharp {
              gatsbyImageData(layout: FIXED)
            }
          }
        }
      }
    }
  }
`
