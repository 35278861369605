import React from "react"
import PropTypes from "prop-types"
// import ScrollUpButton from "react-scroll-up-button"

import { MdKeyboardArrowUp as UpIcon } from "react-icons/md"

import styles from "./top-button.scss"

const TopButton = ({ showPosition, topPosition }) => {
  return (
    // <ScrollUpButton
    //   ContainerClassName={styles.button}
    //   TransitionClassName={styles.buttonToggled}
    //   StopPosition={topPosition}
    //   ShowAtPosition={showPosition}
    // >
    //   <UpIcon />
    // </ScrollUpButton>
    <div></div>
  )
}

TopButton.defaultProps = {
  showPosition: 0,
  topPosition: 0,
}

TopButton.propTypes = {
  showPosition: PropTypes.number,
  topPosition: PropTypes.number,
}

export default TopButton
